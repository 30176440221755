<div class="search-bar-wrapper">
  <lens-search-bar></lens-search-bar>
</div>
<div>
  <lens-criteria-catalogue></lens-criteria-catalogue>
  <div>
    <lens-single-result-renderer
      [resultRenderer]="summaryBar"
    ></lens-single-result-renderer>
    <lens-result-renderer-grid
      [resultRenderers]="diagrams"
    ></lens-result-renderer-grid>
  </div>
</div>
<div class="dkfz-footer">
  <p>
  This federated search was made with the open source <a href="https://github.com/samply/">Samply tools</a> (<a href="https://github.com/samply/lens/">Lens</a>, <a href="https://github.com/samply/beam/">Beam</a>, <a href="https://github.com/samply/focus/">Focus</a>, <a href="https://github.com/samply/bridgehead/">Bridgehead</a>), created by the <a href="https://www.dkfz.de/en/verbis/">German Cancer Research Center (DKFZ)</a>. 
  </p>
</div>
