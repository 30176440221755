<header>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <a class="navbar-brand" href="https://dashboard.eucaim.cancerimage.eu"
      ><img
        src="../assets/images/logoEUCAIM_nav@1.5x-8.png"
        width="131"
        height="37"
        alt="EUCAIM Cancer Image Europe"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto mr-5">
        <li class="nav-item active">
          <a class="nav-link" href="https://dashboard.eucaim.cancerimage.eu"
            >Home <span class="sr-only">(current)</span></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://catalogue.eucaim.cancerimage.eu"
            >PUBLIC CATALOGUE&nbsp;</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://help.cancerimage.eu/#login"
            >HELPDESK&nbsp;</a
          >
        </li>
      </ul>
    </div>
  </nav>
</header>
<app-main></app-main>

<footer
  class="d-flex flex-wrap justify-content-between align-items-end pt-5 mt-auto"
>
  <div class="container row">
    <div class="col-md-8 d-flex align-items-center">
      <a
        href="/"
        class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
      >
        <img
          src="../assets/images/logo_EUCAIM_footer@2x-8.png"
          width="216"
          height="66"
          alt=""
        />
      </a>
    </div>
    <div class="col-md-4"></div>
  </div>
  <div class="container-fluid mb-0" style="background-color: #416097">
    <ul class="nav align-items-center justify-content-center">
      <li class="nav-item">
        <a href="https://dashboard.eucaim.cancerimage.eu/privacy-policy" class="nav-link px-2 text-white">Privacy Policy</a>
      </li>
      <li class="nav-item">
        <a href="#" class="nav-link px-2 text-white">Cookies Policy</a>
      </li>
    </ul>
  </div>
</footer>
