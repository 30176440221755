// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LensConfig } from '@samply/lens-core';
import { Beam } from '@samply/lens-core/cql';

export const environment = {
  production: false,
  lensConfig: new LensConfig(
    [
      new Beam(
        'broker',
        new URL('https://explorer-eucaim.grycap.i3m.upv.es/backend/'),
        ['chaimeleon', 'procanceri']
      ),
    ],
    []
  ),
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
